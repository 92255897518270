
.auth-form-container {
    /* background-color: yellow; */
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-form-container form {
    margin-top: 12px;
    padding: 20px;
    border-radius: 7px;
    background-color: #dfe6e9;

    display: flex;
    flex-direction: column;
}

.auth-form-container form a {
    margin-top: 12px;
    align-self: center;
    text-decoration: none;
}

.message-errors-container {
    display: flex;
    flex-direction: column;
}

.loading-span {
    margin-top: 12px;
    align-self: center;   
}