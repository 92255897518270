.col-sm-3 .company-data-value {
    font-size: 22px;
}

.col-sm-4 .company-data-value,
.col-sm-6 .company-data-value {
    font-size: 32px;
}

.transactions-row-container {
    margin-top: 36px;
}

.panel-body h4 {
    margin-bottom: 22px;
}

#panel-heading-transactions {
    display: flex;
    align-items: center;
}

#panel-heading-transactions span {
    margin-left: 3px;
}

/* .panel-info {
    font-size: 10px;
} */

.pagination-container {
    text-align: center;
}

.table th {
    text-transform: uppercase;
}

.orange {
    color: orange;
}

.green {
    color: green;
}

.red {
    color: red;
}