.dashboard-container {
  padding: 20px;
  /* background-color: grey; */
}

#dashboard-header,
#side-nav-content-container {
  display: flex;
  flex-direction: row;
  /* background-color: blue; */
}

#dashboard-header {
  justify-content: space-between;
  /* background-color: #2980b9; */
  background-color: #bdc3c7;
  color: #fff;
}

#dashboard-header button {
  color: #fff;
}

#dashboard-header h2 {
  /* background-color: gold; */
  margin: 0 15px;
}

#dashboard-header-right-container {
  display: flex;
  align-items: center;
}

#dashboard-side-nav {
  /* background-color: red; */
  /* margin: 20px; */
  font-size: 14px;
  color: #2c3e50;
}

/* #dashboard-side-nav ul li a {
    color: #2c3e50;
} */

.dashboard-content-container {
  /* background-color: gainsboro; */
  flex: 1;
  /* margin-left: 20px; */
  padding: 0 40px;
}

.orange-value {
  color: #f39c12;
}

.green-value {
  color: #16a085;
}

.red-value {
  color: #c0392b;
}

.blue-value {
  color: #3498db;
}

.header-table {
  margin-bottom: 12px;
}

.transaction-filter-form {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.top-action-buttons {
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
}

.action-buttons {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.action-buttons button {
  margin-left: 12px;
}

.field-notice {
  font-size: 12px;
  color: gray;
}

.navbar-nav-items-container {
  margin-right: 28px;
}

.navbar-nav-items-container li a {
  color: #353b48;
}

.navbar-nav-items-container li a:hover {
  background: none;
  color: #7f8fa6;
}

.support-messages {
  margin-top: 16px;
}

.support-messages blockquote {
  background-color: #f5f6fa;
  padding: 12px;
  border-radius: 5px;

  font-family: 'Open Sans', sans-serif;
  font-size: 16px;

  display: flex;
  flex-direction: column;
}

.support-messages blockquote footer {
  align-self: flex-end;
  font-style: italic;
}

#add-support-message-btn {
  padding: 0;
}
