#selected-files-container .table tbody td img {
  min-width: 50px;
  max-width: 100px;
  min-height: 50px;
  max-height: 100px;
}

#selected-files-container .table tbody td.image {
  text-align: center;
  max-width: 50px;
}

#selected-files-container .table tbody td.action-button {
  width: 50px;
}

#selected-files-container a:hover {
  cursor: pointer;
}

#selected-files-container .table tbody td {
  vertical-align: middle;
}
